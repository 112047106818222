




























































import Vue from "vue";
export default Vue.extend({
	data() {
		return {
			applicants: [],
			sortKey: "",
			sortBy: false,
			currentApplicant: 0
		};
	},
	created() {
		this.reload();
	},
	methods: {
		orderBy(key: string) {
			if (this.sortKey == key) {
				this.sortBy = !this.sortBy;
			} else {
				this.sortKey = key;
			}
		},
		reload() {
			this.$store.commit("pushLoading", {
				name: "GET_CLUB_APPLICANT",
				message: "동아리 지원서 불러오는 중"
			});
			this.$store
				.dispatch("GET_CLUB_APPLICANT")
				.then(applicants => {
					this.$store.commit("clearLoading", "GET_CLUB_APPLICANT");
					this.applicants = applicants;
				})
				.catch(err => {});
		},
		accept() {
			this.$store.commit("pushLoading", {
				name: "APPLICANT_ACCEPT",
				message: "동아리 지원서 수락하는 중"
			});
			this.$store
				.dispatch(
					"APPLICANT_ACCEPT",
					this.applicants[this.currentApplicant]
				)
				.then(applicant => {
					this.$store.commit("clearLoading", "APPLICANT_ACCEPT");
					this.reload();
				})
				.catch(err => console.log(err));
		},
		reject() {
			this.$store.commit("pushLoading", {
				name: "APPLICANT_REJECT",
				message: "동아리 지원서 거절하는 중"
			});
			this.$store
				.dispatch(
					"APPLICANT_REJECT",
					this.applicants[this.currentApplicant]
				)
				.then(applicant => {
					this.$store.commit("clearLoading", "APPLICANT_REJECT");

					this.reload();
				})
				.catch(err => console.log(err));
		},
		checkPermission(permission: number) {
			if (this.$store.state.club.ranks) {
				let user = this.$store.state.club.members.find(
					(member: any) => {
						return (
							member.user == this.$store.state.userInformation._id
						);
					}
				);
				if (user)
					return (
						this.$store.state.club.ranks.find(
							(rank: any) => rank.id == user.rank
						).isAdmin ||
						this.$store.state.club.ranks
							.find((rank: any) => rank.id == user.rank)
							.permission.indexOf("" + permission) != -1
					);
				else return false;
			} else return false;
		}
	},
	computed: {
		getOrderedApplicants(): any {
			if (this.sortKey == "") {
				return this.applicants;
			} else {
				return this.applicants.sort((a: any, b: any): any => {
					if (this.sortBy)
						return b[this.sortKey] > a[this.sortKey] ? 1 : -1;
					else return a[this.sortKey] > b[this.sortKey] ? 1 : -1;
				});
			}
		}
	}
});
